import React, { useEffect, useState } from 'react';
import './EventsAttendeesList.scss';
import { PagingTable, RowMapper } from '../../table/PagingTable';
import {
  EmptyEventsAttendeesListRow,
  EventsAttendeesListRow,
} from './EventsAttendeesListRow';

import {
  Attendee,
  Conference,
  Package,
  RoomOccupancy,
  UUID,
} from '../../main/types';
import { AttendeeUpdate, useAttendees } from '../../main/AttendeesProvider';
import { useConference } from '../../main/ConferenceProvider';
import { useBooking } from '../../main/BookingProvider';
import { ModalMessage } from '../../modal/ModalMessage';
import { MessageType } from '../../modal/messages';
import { EventsAttendeeEditModal } from './EventsAttendeeEditModal';
import { EventsAttendeesLockProfilesModal } from './EventsAttendeesLockProfilesModal';

const AttendeesTableHeader = (): React.ReactElement => (
  <tr>
    <th>#</th>
    <th>Nickname</th>
    <th>Name</th>
    <th>Email</th>
    <th>Confirmed / Invoice</th>
    <th>Room type</th>
    <th>Diversity</th>
    <th>Financial Aid</th>
    <th>Roommate</th>
    <th>Family?</th>
    <th>Status</th>
    <th>Invoice No.</th>
    <th>Actions</th>
  </tr>
);

const AttendeesHeader = (): React.ReactElement => (
  <div className="mb-0">
    <h5>Event attendees</h5>
  </div>
);

export const EventsAttendeesList = (): React.ReactElement => {
  const conferenceContext = useConference();
  const attendeeContext = useAttendees();
  const bookingContext = useBooking();
  const [conference, setConference] = useState<Conference | undefined>();
  const [attendees, setAttendees] = useState<Attendee[]>([]);

  const [editAttendee, setEditAttendee] = useState<Attendee | null>(null);
  const [lock, setLock] = useState(false);

  const [messageType, setMessageType] = useState<MessageType | undefined>(
    undefined,
  );
  const [message, setMessage] = useState<string>('');

  useEffect(() => {
    let isSubscribed = true;
    if (!conference) {
      if (conferenceContext.current) {
        setConference(conferenceContext.current);
      } else {
        conferenceContext
          .choices()
          .then((c) => isSubscribed && setConference(c[0]));
      }
    }
    return () => {
      isSubscribed = false;
    };
  }, [conferenceContext, conference]);

  useEffect(() => {
    let isSubscribed = true;
    if (conference) {
      attendeeContext
        .getAttendees(conference.id)
        .then((a) => isSubscribed && setAttendees(a));
    }
    return () => {
      isSubscribed = false;
    };
  }, [attendeeContext, bookingContext, conference]);

  const refresh = async () => {
    if (conference) {
      setAttendees(await attendeeContext.getAttendees(conference.id));
    }
  };

  const onDelete = async (id?: UUID): Promise<void> => {
    await attendeeContext.deleteAttendee(id);
    await refresh();
  };

  const onCreateInvoice = async (
    attendeeId: UUID,
    conferenceId: UUID,
  ): Promise<string> => {
    return await attendeeContext.createAttendeeInvoice(
      attendeeId,
      conferenceId,
    );
  };

  const onReset = async (email?: string): Promise<void> => {
    if (conference) {
      await attendeeContext.resetAccount(conference.id, email);
      await refresh();
    }
  };

  const onResend = async (email?: string): Promise<void> => {
    if (conference) {
      await attendeeContext
        .resendConfirmation(conference.id, email)
        .catch((e) => {
          setMessageType(MessageType.RESEND_FAILED);
          if (e.message.endsWith('409')) {
            setMessage('The account has already been confirmed.');
          } else {
            setMessage(
              'Resending the confirmation mail failed due to: ' + e.message,
            );
          }
          return true;
        })
        .then((error) => {
          if (!error) {
            setMessageType(MessageType.RESEND_SUCCESS);
            setMessage(
              'The confirmation email was successfully resent to the attendee.',
            );
          }
        });
    }
  };

  const onEdit = (email: string): void => {
    if (conference) {
      const attendee = attendees.find((a) => a.email === email);
      if (attendee) {
        setEditAttendee(attendee);
      }
    }
  };

  const onAttendeeEditClose = (): void => {
    setEditAttendee(null);
  };

  const onAttendeeDetailsChanged = async (
    email: string,
    update: AttendeeUpdate,
  ): Promise<void> => {
    if (conference) {
      await attendeeContext.changeAttendeeDetails(conference.id, email, update);
      setEditAttendee(null);
      await refresh();
    }
  };

  const onBookingChange = async (
    email: string,
    packages: Package[],
    room: Partial<RoomOccupancy>,
  ): Promise<void> => {
    if (conference) {
      await attendeeContext.changeBooking(conference.id, email, {
        room,
        packages,
      });
      setEditAttendee(null);
      await refresh();
    }
  };

  const attendeesMapper: RowMapper = (row) => {
    return (
      <EventsAttendeesListRow
        key={row.index}
        attendee={row as Attendee}
        sharers={attendees.filter((a) => !!a.booking).map((b) => b.email) ?? []}
        onDelete={onDelete}
        onReset={onReset}
        onResend={onResend}
        onEdit={onEdit}
        onCreateInvoice={onCreateInvoice}
      />
    );
  };

  const onClose = async (): Promise<void> => {
    setMessageType(undefined);
    setMessage('');
  };

  const onLockProfiles = (): void => {
    setLock(true);
  };

  const onLockProfilesCancel = (): void => {
    setLock(false);
  };

  const onLockProfilesConfirm = async (): Promise<void> => {
    setLock(false);
    await attendeeContext.lockProfiles(conference!.id, attendees);
    setMessage('All current attendee profiles have been locked.');
    setMessageType(MessageType.LOCK_SUCCESS);
  };

  const downloadBadgesXML = async (conf: UUID): Promise<void> => {
    await attendeeContext.downloadBadgesXML(conf);
  };

  const downloadCsv = async (conf: UUID): Promise<void> => {
    await attendeeContext.downloadCsv(conf);
  };
  const downloadStripe = async (conf: UUID): Promise<void> => {
    await attendeeContext.downloadStripe(conf);
  };

  return (
    <>
      <PagingTable
        rows={attendees}
        className={'attendees ' + (conference !== undefined ? '' : 'd-none')}
        listHeader={<AttendeesHeader />}
        tableHeader={<AttendeesTableHeader />}
        emptyRow={<EmptyEventsAttendeesListRow />}
        rowMapper={attendeesMapper}
        lockProfiles={onLockProfiles}
        exportXml={
          conference ? () => downloadBadgesXML(conference.id) : undefined
        }
        exportCsv={conference ? () => downloadCsv(conference.id) : undefined}
        exportStripe={
          conference ? () => downloadStripe(conference.id) : undefined
        }
      />
      {messageType !== undefined && (
        <ModalMessage
          dialogAction={onClose}
          messageType={messageType}
          messageHtml={message}
        />
      )}
      {editAttendee && (
        <EventsAttendeeEditModal
          attendee={editAttendee}
          onAttendeeDetailsChange={onAttendeeDetailsChanged}
          onBookingChange={onBookingChange}
          onClose={onAttendeeEditClose}
        />
      )}
      {lock && (
        <EventsAttendeesLockProfilesModal
          onClose={onLockProfilesCancel}
          onLockProfilesConfirm={onLockProfilesConfirm}
          visible={lock}
        />
      )}
    </>
  );
};
