import { LoggableEvent, Process } from '../main/types';
import moment from 'moment/moment';

// TODO: refactor this, make it readable
export function eventsToProcesses(events: LoggableEvent[]): Process[] {
  return [
    ...Object.values(
      events.reduce(
        (proc, e) => {
          const entry: Process = proc[e.correlationId!]
            ? proc[e.correlationId!]
            : ({
                correlationId: e.correlationId,
                command: undefined,
                lastEvent: undefined,
              } as unknown as Process);
          if (e.type?.endsWith('Command') && entry['command'] === undefined) {
            entry['timestamp'] = moment(e.timestamp).format(
              'YYYY MMM DD - HH:mm:ss',
            );
            entry['command'] = e.type!;
            entry['lastEvent'] = e.type!;
          } else {
            entry['lastEvent'] = e.type!;
          }
          proc[e.correlationId!] = entry;
          return proc;
        },
        {} as Record<string, Process>,
      ),
    ),
  ].sort((a: Process, b: Process) => {
    return a.timestamp > b.timestamp ? 1 : a.timestamp < b.timestamp ? -1 : 0;
  });
}
