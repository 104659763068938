import React, { useRef, useState } from 'react';
import {
  Button,
  Dropdown,
  Modal,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import {
  FaCheckCircle,
  FaExclamationTriangle,
  FaRecycle,
  FaTrash,
  FaUsers,
  FaChild,
} from 'react-icons/fa';
import { HiCurrencyDollar } from 'react-icons/hi';
import {
  MdEdit,
  MdMail,
  MdMarkunreadMailbox,
  MdRequestQuote,
} from 'react-icons/md';
import { Attendee, BillingStatus, Childcare, UUID } from '../../main/types';
import { parsePrice } from '../../common/calculation';

const normalized = (str?: string): string => str?.trim().toLowerCase() ?? '';

type DiversityStatusProps = {
  diversity: string;
};

type ChildcareStatusProps = {
  childcare: Childcare;
};

function DiversityStatus({
  diversity,
}: DiversityStatusProps): React.ReactElement {
  const renderTooltip = (props: any): React.ReactElement => (
    <Tooltip id="button-tooltip" {...props}>
      {diversity}
    </Tooltip>
  );
  const target = useRef(null);
  // noinspection RequiredAttributes
  const icon =
    normalized(diversity) === 'yes' ? (
      <FaCheckCircle className={'text-success'} />
    ) : normalized(diversity) === 'no' ? (
      ''
    ) : (
      <OverlayTrigger
        // @ts-ignore  Type of target attribute is inaccurate
        target={target.current}
        placement="right"
        delay={{ show: 100, hide: 200 }}
        overlay={renderTooltip}
        // @ts-ignore  Type FaCheckCircle won't accept a Ref, but the rendered element does.
      >
        <FaCheckCircle
          // @ts-ignore  Type of target attribute is inaccurate
          ref={target}
          className={'text-warning'}
        />
      </OverlayTrigger>
    );
  return <>{icon}</>;
}

function ChildcareStatus({
  childcare,
}: ChildcareStatusProps): React.ReactElement {
  const renderTooltip = (props: any): React.ReactElement => (
    <Tooltip id="button-tooltip" {...props}>
      {childcare.details}
    </Tooltip>
  );
  const target = useRef(null);
  // noinspection RequiredAttributes
  const icon = (
    <OverlayTrigger
      // @ts-ignore  Type of target attribute is inaccurate
      target={target.current}
      placement="right"
      delay={{ show: 100, hide: 200 }}
      overlay={renderTooltip}
      // @ts-ignore  Type FaCheckCircle won't accept a Ref, but the rendered element does.
    >
      <FaChild
        // @ts-ignore  Type of target attribute is inaccurate
        ref={target}
        className={'text-info'}
      />
    </OverlayTrigger>
  );
  return <>{icon}</>;
}

type EventsAttendeesListRowProps = {
  attendee: Attendee;
  sharers: string[];
  onDelete: (id?: UUID) => void;
  onReset: (email?: string) => void;
  onResend: (email?: string) => void;
  onEdit: (email: string) => void;
  onCreateInvoice: (attendeeId: UUID, conferenceId: UUID) => Promise<string>;
};

type BillingStatusIconProps = {
  billingStatus: BillingStatus;
};
const BillingStatusIcon = ({
  billingStatus,
}: BillingStatusIconProps): React.ReactElement => {
  console.log(billingStatus);
  return (
    <>
      {billingStatus === BillingStatus.NOT_INVOICED ? (
        <HiCurrencyDollar fontSize={'24'} className={'text-muted'} />
      ) : billingStatus === BillingStatus.INVOICED ? (
        <HiCurrencyDollar fontSize={'24'} className={'text-warning'} />
      ) : billingStatus === BillingStatus.PAID ? (
        <HiCurrencyDollar fontSize={'24'} className={'text-success'} />
      ) : billingStatus === BillingStatus.OVERDUE ? (
        <HiCurrencyDollar fontSize={'24'} className={'text-danger'} />
      ) : (
        <FaExclamationTriangle fontSize={'24'} className={'text-danger'} />
      )}
    </>
  );
};

const extractName = (attendee: Attendee): string => {
  if (attendee.personalAddress?.lastname) {
    return (
      attendee.personalAddress.lastname +
      ', ' +
      attendee.personalAddress.firstname
    );
  }

  if (attendee.billingAddress?.lastname) {
    return (
      attendee.billingAddress.lastname +
      ', ' +
      attendee.billingAddress.firstname
    );
  }

  return '';
};

interface ConfirmationModalProps {
  show: boolean;
  onHide: () => void;
  onConfirm: () => void;
  title: string;
  body: string;
  attendee: Attendee;
}

const ConfirmationModal = ({
  show,
  onHide,
  onConfirm,
  title,
  body,
  attendee,
}: ConfirmationModalProps) => (
  <Modal show={show} onHide={onHide}>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {body}
      <div>
        <p>
          <strong>Name:</strong> {attendee.nickname}
        </p>
        <p>
          <strong>Email:</strong> {attendee.email}
        </p>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={onHide}>
        Cancel
      </Button>
      <Button variant="primary" onClick={onConfirm}>
        Confirm
      </Button>
    </Modal.Footer>
  </Modal>
);

export const EventsAttendeesListRow = ({
  attendee,
  sharers,
  onDelete,
  onReset,
  onResend,
  onEdit,
  onCreateInvoice,
}: EventsAttendeesListRowProps): React.ReactElement => {
  const [showResetConfirmation, setShowResetConfirmation] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const handleResetConfirm = () => {
    onReset(attendee.email);
    setShowResetConfirmation(false);
  };

  const handleDeleteConfirm = () => {
    onDelete(attendee.id);
    setShowDeleteConfirmation(false);
  };

  const name = extractName(attendee);
  const booking = attendee.booking;
  const roommate = booking?.room?.roommate ?? '';
  return (
    <tr>
      <td className="text-left align-middle">{(attendee.index || 0) + 1}</td>
      <td className="text-left align-middle">{attendee.nickname}</td>
      <td className="text-left align-middle">{name}</td>
      <td className="text-left align-middle">
        {attendee.email}{' '}
        {attendee.confirmationKey === undefined && name.trim() !== '' ? (
          <FaCheckCircle className={'text-success'} />
        ) : (
          <FaExclamationTriangle className={'text-danger'} />
        )}
      </td>
      <td className="text-center align-middle">
        {attendee.confirmationKey !== undefined ? (
          attendee.confirmationKey
        ) : (
          <BillingStatusIcon
            billingStatus={attendee.billingStatus ?? BillingStatus.NOT_INVOICED}
          />
        )}
      </td>
      <td className="text-left align-middle">
        {booking?.room?.roomType ?? 'single'}
      </td>
      <td className="text-center align-middle">
        <DiversityStatus diversity={booking?.room?.diversity ? 'yes' : 'no'} />
      </td>
      <td className="text-center align-middle">
        {attendee.requestedFinancialAid
          ? 'Req.'
          : attendee.financialAidContrbution &&
              parsePrice(attendee.financialAidContrbution) > 0
            ? attendee.financialAidContrbution
            : ''}
      </td>
      <td className="text-left align-middle">
        {roommate}
        {roommate !== '' &&
          (sharers.indexOf(roommate) > -1 ? (
            <FaCheckCircle className="text-success" />
          ) : (
            <FaExclamationTriangle className="text-danger" />
          ))}
      </td>
      <td className="text-left align-middle">
        {booking?.room?.family && <FaUsers className={'text-info'} />}
        {booking.childcare && <ChildcareStatus childcare={booking.childcare} />}
      </td>
      <td className="text-left align-middle">
        {attendee.reason ?? 'attendee'}
      </td>
      <td className="text-left align-middle"></td>
      <td className="text-center align-middle actions-column">
        <Dropdown>
          <Dropdown.Toggle variant="danger" id="dropdown-basic">
            Select Action
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              href={`mailto:${attendee.email}`}
              title={`Send an email to ${attendee.nickname}`}
            >
              <MdMail /> Send Email
            </Dropdown.Item>
            <Dropdown.Item onClick={() => onEdit(attendee.email)}>
              <MdEdit /> Edit
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => onResend(attendee.email)}
              title={`Resend confirmation email to ${attendee.nickname}`}
            >
              <MdMarkunreadMailbox /> Resend Email
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() =>
                onCreateInvoice(attendee.id, attendee.conferenceId)
              }
              title={`Creates a Lexoffice invoice for ${attendee.nickname}`}
            >
              <MdRequestQuote /> Create Invoice
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setShowResetConfirmation(true)}>
              <FaRecycle /> Reset
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setShowDeleteConfirmation(true)}>
              <FaTrash /> Delete
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <ConfirmationModal
          show={showResetConfirmation}
          onHide={() => setShowResetConfirmation(false)}
          onConfirm={handleResetConfirm}
          title="Confirm Reset"
          body="Are you sure you want to reset the account? This action cannot be undone."
          attendee={attendee}
        />

        <ConfirmationModal
          show={showDeleteConfirmation}
          onHide={() => setShowDeleteConfirmation(false)}
          onConfirm={handleDeleteConfirm}
          title="Confirm Delete"
          body="Are you sure you want to delete this attendee? This action cannot be undone."
          attendee={attendee}
        />
      </td>
    </tr>
  );
};

export const EmptyEventsAttendeesListRow = (): React.ReactElement => (
  <tr>
    <td className="text-center" colSpan={12}>
      There are currently no active attendees.
    </td>
  </tr>
);
